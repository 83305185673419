<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.avatar"
          :text="avatarText(userData.fullName)"
          :variant="`light-${resolveUserRoleVariant(userData.role)}`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ userData.firstName + " " + userData.lastName }}
      </h4>
      <div
        v-if="false"
        class="d-flex flex-wrap"
      >
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
        >
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div>
    </b-media>
    <validation-observer ref="updateUserform">
      <!-- User Info: Input Fields -->
      <b-form>
        <b-row>
          <!-- Field: First Name -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="First Name"
              label-for="firstName"
            >
              <validation-provider
                #default="{ errors }"
                name="First Name"
                rules="required"
              >
                <b-form-input
                  id="firstName"
                  v-model="userData.firstName"
                  :disabled="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Last Name -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Last Name"
              label-for="lastName"
            >
              <validation-provider
                #default="{ errors }"
                name="Last Name"
                rules="required"
              >
                <b-form-input
                  id="lastName"
                  v-model="userData.lastName"
                  :disabled="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Email -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <b-form-input
                  id="email"
                  v-model="userData.email"
                  :disabled="true"
                  type="email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Field: Region -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Region"
              label-for="region"
            >
              <validation-provider
                #default="{ errors }"
                name="Region"
                rules="required"
              >
                <v-select
                  v-model="userData.region"
                  :disabled="true"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="regionOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="region"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Field: Role -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="User Role"
              label-for="user-role"
            >
              <validation-provider
                #default="{ errors }"
                name="User Role"
                rules="required"
              >
                <v-select
                  v-model="userData.role.id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="dataFetchRoles"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="user-role"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Job title -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Job Title"
              label-for="jobTitle"
            >
              <validation-provider
                #default="{ errors }"
                name="Job Title"
                rules="required"
              >
                <b-form-input
                  id="jobTitle"
                  v-model="userData.jobTitle"
                  :disabled="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Field: Title -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Title"
              label-for="title"
            >
              <validation-provider
                #default="{ errors }"
                name="Title"
                rules="required"
              >
                <b-form-input
                  id="title"
                  v-model="userData.title"
                  :disabled="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Field: Company -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Company"
              label-for="company"
            >
              <b-form-input
                id="company"
                v-model="userData.company"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <!-- Field: Reason -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Reason"
              label-for="reason"
            >
              <validation-provider
                #default="{ errors }"
                name="Reason"
                rules=""
              >
                <b-form-input
                  id="reason"
                  v-model="userData.reason"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Field: Duration of use -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Duration of use"
              label-for="duration"
            >
              <validation-provider
                #default="{ errors }"
                name="Date Picker"
                rules="required"
              >
                <date-range-picker
                  ref="picker"
                  v-model="dateRange"
                  :ranges="false"
                  single-date-picker="range"
                  opens="opens-right"
                  :auto-apply="false"
                >
                  <template v-slot:input="picker">
                    {{
                      picker.startDate && picker.endDate
                        ? `${formatDateDDMMYYYY(
                          picker.startDate
                        )} - ${formatDateDDMMYYYY(picker.endDate)}`
                        : "Picker Date"
                    }}
                  </template>
                </date-range-picker>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Field: Audited by Pwc -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="GUP audited"
              label-for="auditedByPWC"
            >
              <v-select
                v-model="userData.auditedByPWC"
                disabled
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="answerQuestionOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="auditedByPWC"
              />
            </b-form-group>
          </b-col>
          <!-- Field: Audited in MY -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Locally audited"
              label-for="auditedInMY"
            >
              <v-select
                v-model="userData.auditedInMY"
                disabled
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="answerQuestionOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="auditedInMY"
              />
            </b-form-group>
          </b-col>
          <!-- Field: Product config -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Asset type config"
              label-for="productConfig"
            >
              <validation-provider
                #default="{ errors }"
                name="Product config"
                rules="required"
              >
                <v-select
                  v-model="userData.productConfig"
                  multiple
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="productConfigTypes"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="auditedInMY"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- PERMISSION TABLE -->
    <b-card
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">Permission</span>
        </b-card-title>
      </b-card-header>
      <b-table
        striped
        responsive
        class="mb-0"
        :items="moduleArray"
      >
        <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox
            disabled
            :checked="data.value"
          />
        </template>
      </b-table>
    </b-card>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="checkValidate"
    >
      Update
    </b-button>
    <b-button
      v-if="false"
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Reset
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { required } from '@validations'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import useUsersList from '@/views/userManager/useUsersList'
import store from '@/store'
import { mapGetters } from 'vuex'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    DateRangePicker,
  },

  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    ...mapGetters('app', ['regionOptions']),
    ...mapGetters('app', ['answerQuestionOptions']),
    ...mapGetters('app', ['productConfigTypes']),
  },
  setup(props) {
    props.userData.productConfig = !props.userData.productConfig
      ? ['low_touch']
      : store.state.app.productConfigTypes.filter(x => props.userData.productConfig.includes(x.value)).map(x => x.value)
    const dateRange = ref({
      startDate: props.userData.startDate,
      endDate: props.userData.endDate,
    })
    const dataFetchRoles = ref([])
    const toast = useToast()
    const fetchRoles = (ctx, callback) => {
      store
        .dispatch('user/fetchRoles', {
          take: 50,
          page: 1,
        })
        .then(response => {
          const roles = response.body.data
          dataFetchRoles.value = roles.map(x => ({
            label: x.name,
            value: x.id,
          }))
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching roles list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    const { resolveUserRoleVariant } = useUsersList()
    const moduleArray = ref([])
    const fetchRole = () => {
      store
        .dispatch('authenticate/fetchRole', props.userData.roleId)
        .then(response => {
          const data = response.body.permissions
          data.forEach(element => {
            if (
              !moduleArray.value.filter(e => e.module === element.object)
                .length > 0
            ) {
              const objectModule = {
                module: element.object,
                read: false,
                write: false,
                create: false,
                delete: false,
              }
              moduleArray.value.push(objectModule)
            }
          })
          data.forEach(x => {
            const objIndex = moduleArray.value.findIndex(
              obj => obj.module === x.object,
            )
            moduleArray.value[objIndex][`${x.code.split('_')[1]}`] = true
          })
        })
        .catch(error => {
          // this.$bvtoast({
          //   component: ToastificationContent,
          //   props: {
          //     title: error.response.data.message,
          //     icon: 'AlertTriangleIcon',
          //     variant: 'danger',
          //   },
          // })
        })
    }
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        // eslint-disable-next-line no-param-reassign
        props.userData.avatar = base64
      },
    )
    const onSubmit = () => {
      const payload = {
        email: props.userData.email,
        region: props.userData.region,
        title: props.userData.title,
        firstName: props.userData.firstName,
        lastName: props.userData.lastName,
        jobTitle: props.userData.jobTitle,
        roleId: props.userData.role.id,
        reason: props.userData.reason,
        auditedByPWC: props.userData.auditedByPWC,
        auditedInMY: props.userData.auditedInMY,
        startDate: dateRange.value.startDate,
        endDate: dateRange.value.endDate,
        productConfig: props.userData.productConfig,
      }
      store
        .dispatch('user/updateUser', { id: props.userData.id, user: payload })
        .then(response => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Update user success',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(e => {
          toast({
            component: ToastificationContent,
            props: {
              title: e.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    setTimeout(async () => {
      fetchRoles()
      fetchRole()
    }, 0)
    return {
      router,
      resolveUserRoleVariant,
      avatarText,
      moduleArray,
      onSubmit,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
      dataFetchRoles,
      dateRange,
    }
  },
  methods: {
    formatDateDDMMYYYY(time) {
      return moment(time).format('DD/MM/YYYY')
    },
    checkValidate() {
      this.$refs.updateUserform.validate().then(success => {
        if (success) {
          this.onSubmit()
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
