var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-media',{staticClass:"mb-2",scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{ref:"previewEl",attrs:{"src":_vm.userData.avatar,"text":_vm.avatarText(_vm.userData.fullName),"variant":("light-" + (_vm.resolveUserRoleVariant(_vm.userData.role))),"size":"90px","rounded":""}})]},proxy:true}])},[_c('h4',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.userData.firstName + " " + _vm.userData.lastName)+" ")]),(false)?_c('div',{staticClass:"d-flex flex-wrap"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$refs.refInputEl.click()}}},[_c('input',{ref:"refInputEl",staticClass:"d-none",attrs:{"type":"file"},on:{"input":_vm.inputImageRenderer}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Update")]),_c('feather-icon',{staticClass:"d-inline d-sm-none",attrs:{"icon":"EditIcon"}})],1),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"outline-secondary"}},[_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Remove")]),_c('feather-icon',{staticClass:"d-inline d-sm-none",attrs:{"icon":"TrashIcon"}})],1)],1):_vm._e()]),_c('validation-observer',{ref:"updateUserform"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"First Name","label-for":"firstName"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"firstName","disabled":true},model:{value:(_vm.userData.firstName),callback:function ($$v) {_vm.$set(_vm.userData, "firstName", $$v)},expression:"userData.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Last Name","label-for":"lastName"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lastName","disabled":true},model:{value:(_vm.userData.lastName),callback:function ($$v) {_vm.$set(_vm.userData, "lastName", $$v)},expression:"userData.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","disabled":true,"type":"email"},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Region","label-for":"region"}},[_c('validation-provider',{attrs:{"name":"Region","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.regionOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"region"},model:{value:(_vm.userData.region),callback:function ($$v) {_vm.$set(_vm.userData, "region", $$v)},expression:"userData.region"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"User Role","label-for":"user-role"}},[_c('validation-provider',{attrs:{"name":"User Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.dataFetchRoles,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"user-role"},model:{value:(_vm.userData.role.id),callback:function ($$v) {_vm.$set(_vm.userData.role, "id", $$v)},expression:"userData.role.id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Job Title","label-for":"jobTitle"}},[_c('validation-provider',{attrs:{"name":"Job Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"jobTitle","disabled":true},model:{value:(_vm.userData.jobTitle),callback:function ($$v) {_vm.$set(_vm.userData, "jobTitle", $$v)},expression:"userData.jobTitle"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Title","label-for":"title"}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","disabled":true},model:{value:(_vm.userData.title),callback:function ($$v) {_vm.$set(_vm.userData, "title", $$v)},expression:"userData.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Company","label-for":"company"}},[_c('b-form-input',{attrs:{"id":"company","disabled":true},model:{value:(_vm.userData.company),callback:function ($$v) {_vm.$set(_vm.userData, "company", $$v)},expression:"userData.company"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Reason","label-for":"reason"}},[_c('validation-provider',{attrs:{"name":"Reason","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"reason"},model:{value:(_vm.userData.reason),callback:function ($$v) {_vm.$set(_vm.userData, "reason", $$v)},expression:"userData.reason"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Duration of use","label-for":"duration"}},[_c('validation-provider',{attrs:{"name":"Date Picker","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-range-picker',{ref:"picker",attrs:{"ranges":false,"single-date-picker":"range","opens":"opens-right","auto-apply":false},scopedSlots:_vm._u([{key:"input",fn:function(picker){return [_vm._v(" "+_vm._s(picker.startDate && picker.endDate ? ((_vm.formatDateDDMMYYYY( picker.startDate )) + " - " + (_vm.formatDateDDMMYYYY(picker.endDate))) : "Picker Date")+" ")]}}],null,true),model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"GUP audited","label-for":"auditedByPWC"}},[_c('v-select',{attrs:{"disabled":"","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.answerQuestionOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"auditedByPWC"},model:{value:(_vm.userData.auditedByPWC),callback:function ($$v) {_vm.$set(_vm.userData, "auditedByPWC", $$v)},expression:"userData.auditedByPWC"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Locally audited","label-for":"auditedInMY"}},[_c('v-select',{attrs:{"disabled":"","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.answerQuestionOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"auditedInMY"},model:{value:(_vm.userData.auditedInMY),callback:function ($$v) {_vm.$set(_vm.userData, "auditedInMY", $$v)},expression:"userData.auditedInMY"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Asset type config","label-for":"productConfig"}},[_c('validation-provider',{attrs:{"name":"Product config","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"multiple":"","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.productConfigTypes,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"auditedInMY"},model:{value:(_vm.userData.productConfig),callback:function ($$v) {_vm.$set(_vm.userData, "productConfig", $$v)},expression:"userData.productConfig"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('b-card',{staticClass:"border mt-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-1"},[_c('b-card-title',{staticClass:"font-medium-2"},[_c('feather-icon',{attrs:{"icon":"LockIcon","size":"18"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Permission")])],1)],1),_c('b-table',{staticClass:"mb-0",attrs:{"striped":"","responsive":"","items":_vm.moduleArray},scopedSlots:_vm._u([{key:"cell(module)",fn:function(data){return [_vm._v(" "+_vm._s(data.value)+" ")]}},{key:"cell()",fn:function(data){return [_c('b-form-checkbox',{attrs:{"disabled":"","checked":data.value}})]}}])})],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":_vm.checkValidate}},[_vm._v(" Update ")]),(false)?_c('b-button',{attrs:{"variant":"outline-secondary","type":"reset","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'}},[_vm._v(" Reset ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }