<!-- eslint-disable import/no-cycle -->
<template>
  <component :is="roleData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="roleData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>
    <!-- <rbac-edit-tab
      :role-data="roleData"
      class="mt-2 pt-75"
    /> -->
    <div>
      <div>
        <validation-observer ref="updateUserform">
          <!-- User Info: Input Fields -->
          <b-form>
            <b-row>
              <!-- Field: Role Name -->
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Role Name"
                  label-for="roleName"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Role Name"
                    rules="required"
                  >
                    <b-form-input
                      id="roleName"
                      v-model="roleData.name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Field: Description -->
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Description"
                  label-for="description"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Description"
                    rules="required"
                  >
                    <b-form-input
                      id="description"
                      v-model="roleData.description"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Field: Region -->
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Region"
                  label-for="region"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Region"
                    rules="required"
                  >
                    <v-select
                      v-model="roleData.region"
                      multiple
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="regionNumberOptions"
                      :clearable="false"
                      input-id="region"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
              >
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Permissions"
                    rules="required"
                  >
                    <!-- PERMISSION TABLE -->
                    <b-card
                      no-body
                      class="border mt-1"
                    >
                      <b-card-header
                        v-model="permissionIds"
                        class="p-1"
                      >
                        <b-card-title class="font-medium-2">
                          <feather-icon
                            icon="LockIcon"
                            size="18"
                          />
                          <span class="align-middle ml-50">Permission</span>
                        </b-card-title>
                      </b-card-header>
                      <b-table
                        striped
                        responsive
                        class="mb-0"
                        :items="moduleArray"
                      >
                        <template #cell(module)="data">
                          {{ data.value }}
                        </template>
                        <template #cell()="data">
                          <b-form-checkbox
                            :disabled="!data.value.status"
                            :checked="data.value.value"
                            @change="onCheckModule(data)"
                          />
                        </template>
                      </b-table>
                    </b-card>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>

      <!-- Action Buttons -->
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="checkValidate"
      >
        Update
      </b-button>
      <b-button
        v-if="false"
        variant="outline-secondary"
        type="reset"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        Reset
      </b-button>
    </div>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
  BRow, BCol, BForm, BFormGroup, BButton, BTable, BFormInput,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import RbacEditTab from '@/components/rbac/edit/RbacEditTab.vue'
import UserEditTabAccount from '@/components/userManager/edit/UserEditTabAccount.vue'
import UserEditTabInformation from '@/components/userManager/edit/UserEditTabInformation.vue'
import UserEditTabSocial from '@/components/userManager/edit/UserEditTabSocial.vue'
import { mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    BFormInput,
    BCol,
    BForm,
    BRow,
    BFormGroup,
    BButton,
    BTable,
    ValidationObserver,
    ValidationProvider,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    RbacEditTab,
    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabSocial,
  },
  computed: {
    ...mapGetters('app', ['regionNumberOptions']),
  },
  setup() {
    const roleData = ref({})
    const permissionIds = ref([])
    const dataFetchRoles = ref([])
    const toast = useToast()
    const moduleArray = ref([])
    const fetchRole = () => {
      store.dispatch('authenticate/fetchRole', router.currentRoute.params.id)
        .then(response => {
          roleData.value = {
            id: response.body.id,
            name: response.body.name,
            description: response.body.description,
            region: response.body.roleRegions.map(x => ({
              label: store.state.app.regionNumberOptions.find(e => e.value === x.regionId).label,
              value: x.regionId,
            })),
            permissions: response.body.permissions,
          }
          response.body.permissions.forEach(i => {
            const objIndex = moduleArray.value.findIndex(
              obj => obj.module === i.object,
            )
            moduleArray.value[objIndex][`${i.action}`].value = true
            permissionIds.value.push(
              {
                item: {
                  module: i.object,
                },
                field: {
                  key: i.action,
                },
                value: true,
              },
            )
          })
        })
        .catch(error => {
        })
    }
    const fetchPermissions = (ctx, callback) => {
      moduleArray.value = []
      store
        .dispatch('user/fetchPermissions', {
          take: 10,
          page: 1,
        })
        .then(response => {
          dataFetchRoles.value = response.body
          dataFetchRoles.value.forEach(element => {
            if (
              !moduleArray.value.filter(e => e.module === element.object)
                .length > 0
            ) {
              const objectModule = {
                module: element.object,
                read: {
                  value: false,
                  status: false,
                },
                update: {
                  value: false,
                  status: false,
                },
                create: {
                  value: false,
                  status: false,
                },
                delete: {
                  value: false,
                  status: false,
                },
                deactivate: {
                  value: false,
                  status: false,
                },
                approved: {
                  value: false,
                  status: false,
                },
              }
              moduleArray.value.push(objectModule)
            }
          })

          dataFetchRoles.value.forEach(x => {
            const objIndex = moduleArray.value.findIndex(
              obj => obj.module === x.object,
            )
            moduleArray.value[objIndex][x.action].status = true
          })
        })
      setTimeout(fetchRole, 100);
    }

    const onSubmit = () => {
      const arrID = []
      permissionIds.value.forEach(e => {
        arrID.push(dataFetchRoles.value.find(i => i.object === e.item.module && i.action === e.field.key).id)
      })
      const payload = {
        name: roleData.value.name,
        description: roleData.value.description,
        regionIds: roleData.value.region.map(x => x.value),
        permissionIds: arrID,
        isActive: true,
      }
      store.dispatch('user/updateRole', { id: router.currentRoute.params.id, role: payload })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Update permission success',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(e => {
          toast({
            component: ToastificationContent,
            props: {
              title: e.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    setTimeout(() => {
      fetchPermissions()
    }, 0)
    return {
      onSubmit,
      dataFetchRoles,
      moduleArray,
      permissionIds,
      roleData,
    }
  },
  methods: {
    checkValidate() {
      this.$refs.updateUserform.validate().then(success => {
        if (success) {
          this.onSubmit()
        }
      })
    },
    onCheckModule(data) {
      const valueData = data.value
      this.moduleArray.find(e => e.module === data.item.module)[data.field.key].value = !valueData.value
      if (this.permissionIds.filter(e => e.item.module === data.item.module && e.field.key === data.field.key).length > 0) {
        this.permissionIds.splice(this.permissionIds.findIndex(e => e.item.module === data.item.module && e.field.key === data.field.key), 1)
      } else {
        this.permissionIds.push(data)
      }
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
